import React from "react";
import Layout from "../components/Layout";
import PageTemplate from "../templates/page";
import Seo from "../components/Seo";

const slides = [
  { content: "Not found", fontSize: "2xl" },
  { content: "Return home?", link: "/", fontSize: "xl" },
];

const NotFoundPage = () => (
  <Layout>
    <Seo title={"Not found"} />
    <PageTemplate slides={slides} />
  </Layout>
);

export default NotFoundPage;
